// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-component {
    display: inline-flex;
    position: fixed;
    background-color: #424242;
    height: 7vw;
    min-height: 50px;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
}

.logo-button {
    display: inline-block;
    position: absolute;
    border: none;
    width: 6vw;
    min-width: 50px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}`, "",{"version":3,"sources":["webpack://./src/styles/header/HeaderComponent.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,eAAe;IACf,yBAAyB;IACzB,WAAW;IACX,gBAAgB;IAChB,WAAW;IACX,MAAM;IACN,OAAO;IACP,UAAU;AACd;;AAEA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,YAAY;IACZ,UAAU;IACV,eAAe;IACf,kBAAkB;IAClB,QAAQ;IAER,2BAA2B;AAC/B","sourcesContent":[".header-component {\r\n    display: inline-flex;\r\n    position: fixed;\r\n    background-color: #424242;\r\n    height: 7vw;\r\n    min-height: 50px;\r\n    width: 100%;\r\n    top: 0;\r\n    left: 0;\r\n    z-index: 1;\r\n}\r\n\r\n.logo-button {\r\n    display: inline-block;\r\n    position: absolute;\r\n    border: none;\r\n    width: 6vw;\r\n    min-width: 50px;\r\n    position: absolute;\r\n    top: 50%;\r\n    -ms-transform: translateY(-50%);\r\n    transform: translateY(-50%);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
