// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content {
    margin-top: 9vw;
    width: 90%;
}`, "",{"version":3,"sources":["webpack://./src/styles/Content.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,UAAU;AACd","sourcesContent":[".content {\r\n    margin-top: 9vw;\r\n    width: 90%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
