// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.project-details iframe {
    width: 30vw;
    height: 20vw;
    margin-top: 5vw;
    pointer-events: none;
}

.project-details h1 {
    font-size: 3vw;
}

.project-details p {
    margin-top: 3vw;
    font-size: 2vw;
}

.project-details a {
    display: block;
    text-decoration: none;
    margin-top: 10vw;
    margin-bottom: 10vw;
    margin-left: auto;
    margin-right: auto;
    padding: 1.5vw;
    width: 15vw;
    height: 5vw;
    border-radius: 20%;
    color: white;
    background-color: black;
    font-size: 1.5vw;
    box-shadow: 1px 1px white;
}

.project-details a:hover {
    color: black;
    background-color: white;
    border-color: black;
    box-shadow: none;
}`, "",{"version":3,"sources":["webpack://./src/styles/ProjectDetails.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,eAAe;IACf,oBAAoB;AACxB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,qBAAqB;IACrB,gBAAgB;IAChB,mBAAmB;IACnB,iBAAiB;IACjB,kBAAkB;IAClB,cAAc;IACd,WAAW;IACX,WAAW;IACX,kBAAkB;IAClB,YAAY;IACZ,uBAAuB;IACvB,gBAAgB;IAChB,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;AACpB","sourcesContent":[".project-details iframe {\r\n    width: 30vw;\r\n    height: 20vw;\r\n    margin-top: 5vw;\r\n    pointer-events: none;\r\n}\r\n\r\n.project-details h1 {\r\n    font-size: 3vw;\r\n}\r\n\r\n.project-details p {\r\n    margin-top: 3vw;\r\n    font-size: 2vw;\r\n}\r\n\r\n.project-details a {\r\n    display: block;\r\n    text-decoration: none;\r\n    margin-top: 10vw;\r\n    margin-bottom: 10vw;\r\n    margin-left: auto;\r\n    margin-right: auto;\r\n    padding: 1.5vw;\r\n    width: 15vw;\r\n    height: 5vw;\r\n    border-radius: 20%;\r\n    color: white;\r\n    background-color: black;\r\n    font-size: 1.5vw;\r\n    box-shadow: 1px 1px white;\r\n}\r\n\r\n.project-details a:hover {\r\n    color: black;\r\n    background-color: white;\r\n    border-color: black;\r\n    box-shadow: none;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
