// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact {
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact form {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 80vw;
    max-width: 800px;
}

.contact form div {
    display: grid;
    grid-template-columns: 50% 50%;
    width: 100%;
    margin-bottom: 1vh;
}

.contact form label {
    text-align: start;
    margin-bottom: 1vh;
}

.contact form textarea {
    width: 100%;
    height: 20vh;
}

.contact form input,
.contact form select {
    width: var(--input-width);
}

.contact>form,
.contact>form ::placeholder,
.contact form select {
    font-size: 2vh;
}

.contact form input[type="submit"] {
    justify-self: center;
    align-self: center;
    margin-top: 1vw;
    width: 80%;
    height: 5vh;
}`, "",{"version":3,"sources":["webpack://./src/styles/Contact.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;;IAEI,yBAAyB;AAC7B;;AAEA;;;IAGI,cAAc;AAClB;;AAEA;IACI,oBAAoB;IACpB,kBAAkB;IAClB,eAAe;IACf,UAAU;IACV,WAAW;AACf","sourcesContent":[".contact {\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n}\r\n\r\n.contact form {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: start;\r\n    width: 80vw;\r\n    max-width: 800px;\r\n}\r\n\r\n.contact form div {\r\n    display: grid;\r\n    grid-template-columns: 50% 50%;\r\n    width: 100%;\r\n    margin-bottom: 1vh;\r\n}\r\n\r\n.contact form label {\r\n    text-align: start;\r\n    margin-bottom: 1vh;\r\n}\r\n\r\n.contact form textarea {\r\n    width: 100%;\r\n    height: 20vh;\r\n}\r\n\r\n.contact form input,\r\n.contact form select {\r\n    width: var(--input-width);\r\n}\r\n\r\n.contact>form,\r\n.contact>form ::placeholder,\r\n.contact form select {\r\n    font-size: 2vh;\r\n}\r\n\r\n.contact form input[type=\"submit\"] {\r\n    justify-self: center;\r\n    align-self: center;\r\n    margin-top: 1vw;\r\n    width: 80%;\r\n    height: 5vh;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
