// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.project {}

.project-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}`, "",{"version":3,"sources":["webpack://./src/styles/Projects.css"],"names":[],"mappings":"AAAA,UAAU;;AAEV;IACI,aAAa;IACb,kCAAkC;AACtC","sourcesContent":[".project {}\r\n\r\n.project-list {\r\n    display: grid;\r\n    grid-template-columns: 1fr 1fr 1fr;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
