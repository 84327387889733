// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile {

    text-align: start;
}

.stacks {
    display: flex;
    width: 70%;
    flex-wrap: wrap;
}

.stacks img {
    width: 5vw;
    height: 5vw;
    margin-right: 2vw;
}

.stacks i {
    font-size: 5vw;
    margin-right: 5vw;
    margin-bottom: 3vw;
}

.stacks a {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    -ms-align-items: center;
    overflow: hidden;
    font-size: large;
    z-index: 0;
    border: 0;
    font-size: 5vw;
    margin-right: 5vw;
    margin-bottom: 3vw;
    text-decoration: none;
    color: inherit;
}`, "",{"version":3,"sources":["webpack://./src/styles/Profile.css"],"names":[],"mappings":"AAAA;;IAEI,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,UAAU;IACV,eAAe;AACnB;;AAEA;IACI,UAAU;IACV,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,uBAAuB;IACvB,mBAAmB;IACnB,uBAAuB;IACvB,gBAAgB;IAChB,gBAAgB;IAChB,UAAU;IACV,SAAS;IACT,cAAc;IACd,iBAAiB;IACjB,kBAAkB;IAClB,qBAAqB;IACrB,cAAc;AAClB","sourcesContent":[".profile {\r\n\r\n    text-align: start;\r\n}\r\n\r\n.stacks {\r\n    display: flex;\r\n    width: 70%;\r\n    flex-wrap: wrap;\r\n}\r\n\r\n.stacks img {\r\n    width: 5vw;\r\n    height: 5vw;\r\n    margin-right: 2vw;\r\n}\r\n\r\n.stacks i {\r\n    font-size: 5vw;\r\n    margin-right: 5vw;\r\n    margin-bottom: 3vw;\r\n}\r\n\r\n.stacks a {\r\n    display: flex;\r\n    position: relative;\r\n    justify-content: center;\r\n    align-items: center;\r\n    -ms-align-items: center;\r\n    overflow: hidden;\r\n    font-size: large;\r\n    z-index: 0;\r\n    border: 0;\r\n    font-size: 5vw;\r\n    margin-right: 5vw;\r\n    margin-bottom: 3vw;\r\n    text-decoration: none;\r\n    color: inherit;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
