// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.showcase-text {
    display: none;
    position: absolute;
    align-items: center;
    justify-content: center;
    left: 35%;
    top: 25%;
    width: 30%;
    height: 50%;
    background-color: rgba(128, 128, 128, 0.7);
}

.showcase-text p {
    text-decoration: none;
    color: white;
}


.showcase-item div iframe {
    width: 90vw;
    height: 30vw;
    pointer-events: none;
}

.showcase-item :hover .showcase-text {
    display: flex;
}`, "",{"version":3,"sources":["webpack://./src/styles/home_component/ProjectShowcase.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;IAClB,mBAAmB;IACnB,uBAAuB;IACvB,SAAS;IACT,QAAQ;IACR,UAAU;IACV,WAAW;IACX,0CAA0C;AAC9C;;AAEA;IACI,qBAAqB;IACrB,YAAY;AAChB;;;AAGA;IACI,WAAW;IACX,YAAY;IACZ,oBAAoB;AACxB;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".showcase-text {\r\n    display: none;\r\n    position: absolute;\r\n    align-items: center;\r\n    justify-content: center;\r\n    left: 35%;\r\n    top: 25%;\r\n    width: 30%;\r\n    height: 50%;\r\n    background-color: rgba(128, 128, 128, 0.7);\r\n}\r\n\r\n.showcase-text p {\r\n    text-decoration: none;\r\n    color: white;\r\n}\r\n\r\n\r\n.showcase-item div iframe {\r\n    width: 90vw;\r\n    height: 30vw;\r\n    pointer-events: none;\r\n}\r\n\r\n.showcase-item :hover .showcase-text {\r\n    display: flex;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
