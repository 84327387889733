// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-lang-selector {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    margin-right: 2vw;
}

.header-lang-selector select {

    width: 7vw;
    height: 7vw;
    max-width: 100px;
    max-height: 100px;
    font-size: min(2vw, 25px);
    appearance: none;
    border-radius: 20%;
    text-align-last: center;

}

.header-lang-selector select option {

    width: 50%;
    height: 50%;
}`, "",{"version":3,"sources":["webpack://./src/styles/header/HeaderLangSelector.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,mBAAmB;IACnB,uBAAuB;IACvB,WAAW;IACX,YAAY;;IAEZ,iBAAiB;AACrB;;AAEA;;IAEI,UAAU;IACV,WAAW;IACX,gBAAgB;IAChB,iBAAiB;IACjB,yBAAyB;IACzB,gBAAgB;IAChB,kBAAkB;IAClB,uBAAuB;;AAE3B;;AAEA;;IAEI,UAAU;IACV,WAAW;AACf","sourcesContent":[".header-lang-selector {\r\n    display: inline-flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    width: 100%;\r\n    height: 100%;\r\n\r\n    margin-right: 2vw;\r\n}\r\n\r\n.header-lang-selector select {\r\n\r\n    width: 7vw;\r\n    height: 7vw;\r\n    max-width: 100px;\r\n    max-height: 100px;\r\n    font-size: min(2vw, 25px);\r\n    appearance: none;\r\n    border-radius: 20%;\r\n    text-align-last: center;\r\n\r\n}\r\n\r\n.header-lang-selector select option {\r\n\r\n    width: 50%;\r\n    height: 50%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
