// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.project-component {
    display: block;
    width: 25vw;
    height: 15vw;
    text-decoration: none;
    color: white;
}

.project-component:hover {
    background-color: aliceblue;
    color: black;
}

.project-component img {
    width: 18vw;
    height: 10vw;
    margin-top: 1vh;
}

.project-component iframe {
    width: 18vw;
    height: 10vw;
    margin-top: 1vh;
    pointer-events: none;
}`, "",{"version":3,"sources":["webpack://./src/styles/project_component/ProjectComponent.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,WAAW;IACX,YAAY;IACZ,qBAAqB;IACrB,YAAY;AAChB;;AAEA;IACI,2BAA2B;IAC3B,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,eAAe;IACf,oBAAoB;AACxB","sourcesContent":[".project-component {\r\n    display: block;\r\n    width: 25vw;\r\n    height: 15vw;\r\n    text-decoration: none;\r\n    color: white;\r\n}\r\n\r\n.project-component:hover {\r\n    background-color: aliceblue;\r\n    color: black;\r\n}\r\n\r\n.project-component img {\r\n    width: 18vw;\r\n    height: 10vw;\r\n    margin-top: 1vh;\r\n}\r\n\r\n.project-component iframe {\r\n    width: 18vw;\r\n    height: 10vw;\r\n    margin-top: 1vh;\r\n    pointer-events: none;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
